import { createRouter, createWebHistory } from 'vue-router'
import {
  authGuard,
  adminGuard,
  projectGuard,
} from '@/composables/useNavigationGuard'
import {
  prefetchProject,
  prefetchUser,
  prefetchActiveLocation,
  prefetchMetricsForKpi,
} from '@/composables/usePrefetching.js'

const routes = [
  {
    path: '/',
    name: 'loading',
    component: () => import('@/pages/index.vue'),
    beforeEnter: prefetchUser,
    meta: {
      layout: 'Unauthenticated',
      requiresAuth: true,
    },
  },
  /**
   * Admin only page, overview of all connected partners
   */
  {
    path: '/admin/clients',
    name: 'clientsPage',
    component: () => import('@/pages/admin/clients/index.vue'),
    meta: {
      layout: 'Authenticated',
      label: 'Clients',
      icon: 'fa-dice-d6',
      admin: true,
      requiresAuth: true,
    },
    // Any admin pages will require this guard to block access
    beforeEnter: adminGuard,
  },
  /**
   * Authorisation routes, used for logging in and registering
   */
  {
    path: '/auth/callback',
    name: 'AuthCallback',
    component: () => import('@/pages/auth/callback.vue'),
    meta: {
      layout: 'Unauthenticated',
      requiresAuth: false,
    },
  },
  {
    path: '/auth/login',
    name: 'loginPage',
    component: () => import('@/pages/auth/login.vue'),
    meta: {
      layout: 'Unauthenticated',
      requiresAuth: false,
    },
  },
  {
    path: '/auth/register/:secret_url_code',
    name: 'registerPage',
    component: () => import('@/pages/auth/register.vue'),
    meta: {
      layout: 'Unauthenticated',
      requiresAuth: false,
    },
  },
  {
    path: '/auth/missing-user',
    name: 'missingUserPage',
    component: () => import('@/pages/auth/missing-user.vue'),
    meta: {
      layout: 'Unauthenticated',
      requiresAuth: true,
    },
  },
  {
    path: '/auth/logout',
    name: 'logoutPage',
    component: () => import('@/pages/auth/logout.vue'),
    meta: {
      layout: 'Unauthenticated',
      requiresAuth: false,
    },
  },
  /**
   * Project routes, omitting the parent component is required for
   * nested children to be rendered using the top level <router-view>
   */
  {
    path: '/project/:projectId',
    meta: {
      layout: 'Authenticated',
      projectMenu: false,
    },
    children: [
      {
        path: '',
        name: 'projectPage',
        component: () => import('@/pages/project/index.vue'),
        /**
         * Handles prefetching of project data.
         */
        beforeEnter: [projectGuard, prefetchProject],
        meta: {
          layout: 'Authenticated',
          label: 'Locations',
          icon: 'location-arrow',
          projectMenu: true,
          requiresAuth: true,
        },
      },
      {
        path: 'team',
        name: 'ClientTeamDisplay',
        component: () => import('@/pages/project/team.vue'),
        beforeEnter: [projectGuard, prefetchProject],
        meta: {
          layout: 'Authenticated',
          label: 'Team',
          icon: 'user-group',
          projectMenu: true,
          requiresAuth: true,
        },
      },
      {
        path: 'summarydashboard',
        name: 'ProjectDashboard',
        component: () => import('@/pages/project/overview.vue'),
        beforeEnter: [projectGuard, prefetchProject],
        meta: {
          layout: 'Authenticated',
          label: 'Overview & KPIs',
          icon: 'layer-group',
          projectMenu: true,
          requiresAuth: true,
        },
      },
      {
        path: 'reports',
        name: 'ProjectReports',
        component: () => import('@/pages/project/reports.vue'),
        beforeEnter: [projectGuard, prefetchProject],
        meta: {
          layout: 'Authenticated',
          label: 'Reports',
          icon: 'file-text',
          projectMenu: true,
          requiresAuth: true,
        },
      },
      {
        path: 'location/:locationId',
        meta: {
          layout: 'Authenticated',
          locationMenu: false,
        },
        children: [
          {
            path: '',
            name: 'LocationMap',
            component: () => import('@/pages/location/map.vue'),
            beforeEnter: [prefetchProject, prefetchActiveLocation],
            meta: {
              layout: 'Authenticated',
              label: 'Sensors',
              icon: 'microchip',
              locationMenu: true,
              requiresAuth: true,
            },
          },
          {
            path: 'dashboard',
            name: 'LocationDashboard',
            component: () => import('@/pages/location/overview.vue'),
            beforeEnter: [prefetchProject, prefetchActiveLocation],
            meta: {
              layout: 'Authenticated',
              requiresAuth: true,
              label: 'Overview',
              icon: 'square-poll-vertical',
              locationMenu: true,
            },
          },
          {
            path: 'kpis',
            meta: {
              layout: 'Authenticated',
              locationMenu: false,
            },
            children: [
              {
                path: '',
                name: 'KPIPage',
                component: () => import('@/pages/location/kpis.vue'),
                beforeEnter: [prefetchProject, prefetchActiveLocation],
                meta: {
                  layout: 'Authenticated',
                  label: 'KPIs',
                  icon: 'chart-line',
                  requiresAuth: true,
                  locationMenu: true,
                },
              },
              {
                path: ':kpiId/metrics',
                meta: {
                  layout: 'Authenticated',
                  locationMenu: false,
                },
                children: [
                  {
                    path: '',
                    name: 'MetricPage',
                    component: () => import('@/pages/location/metrics.vue'),
                    beforeEnter: [prefetchProject, prefetchActiveLocation, prefetchMetricsForKpi],
                    meta: {
                      layout: 'Authenticated',
                      label: 'Metrics',
                      requiresAuth: true,
                      locationMenu: false,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'loading' },
  },
  {
    path: '/internal-server-error',
    name: 'InternalServerErrorPage',
    component: () => import('@/pages/errors/internal-error.vue'),
    meta: {
      layout: 'Unauthenticated',
      requiresAuth: true,
    },
  },
  {
    path: '/not-found',
    name: 'ResourceNotFoundPage',
    component: () => import('@/pages/errors/not-found.vue'),
    meta: {
      layout: 'Unauthenticated',
      requiresAuth: true,
    },
  },
  {
    path: '/there-was-an-issue',
    name: 'IssuePage',
    component: () => import('@/pages/errors/issue.vue'),
    meta: {
      layout: 'Unauthenticated',
      requiresAuth: true,
    },
  },
  {
    path: '/userlogin',
    redirect: { name: 'loading' },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from) => {
  if (to.meta?.requiresAuth) {
    return await authGuard(to, from)
  }
})

export default router
