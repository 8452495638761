import { defineStore } from 'pinia'
import { ref } from 'vue'
import { usePortalAPI } from '@/composables/usePortalAPI'
import { sentryException } from '@/plugins/sentry.js'
import { toast } from 'vue-sonner'

// TODO: remove this
export const useConnectedUser = defineStore('ConnectedUser', {
  state() {
    return {
      data: 'working',
      project: '',
      projectid: '',
      location: '',
      locationid: '',
      hasRAG: false,
    }
  },
})

export const useProjectStore = defineStore('projectStore', () => {
  const projects = ref([])
  const activeProject = ref(null)
  const projectUsers = ref(null)
  const locations = ref([])
  const activeLocation = ref(null)
  const loading = ref(false)
  const hasRAG = ref(false)

  const reports = ref(null)

  const locationAssets = ref(null)
  const selectedAssets = ref([])

  /**
   * Fetch all partner projects, only viewable by superusers
   * @returns {Promise<void>} All of our current partners
   */
  const fetchAllProjects = async () => {
    try {
      const response = await usePortalAPI('controlpanel/projects/', {
        method: 'GET',
      })
      if (response) {
        projects.value = response
      }
    } catch (error) {
      sentryException(error, {})
      toast.error('There was an issue loading all projects. Please try again.')
    }
  }

  const fetchProjectById = async (id) => {
    try {
      const response = await usePortalAPI(`dashboard/projects/${id}/`, {
        method: 'GET',
      })
      if (response) {
        activeProject.value = response
        locations.value = response.locations
      }
    } catch (error) {
      throw error
    }
  }

  const fetchProjectUsersById = async (id) => {
    try {
      const response = await usePortalAPI(`dashboard/projects/${id}/users/`, {
        method: 'GET',
      })
      if (response) {
        projectUsers.value = response
      }
    } catch (error) {
      sentryException(error, {})
      toast.error(
        'There was an issue loading your team members. Please try again.',
      )
    }
  }

  const fetchProjectReportsById = async (id) => {
    try {
      const response = await usePortalAPI(`reports/projects/${id}/reports/`, {
        method: 'GET',
      })
      if (response) {
        reports.value = response
      }
    } catch (error) {
      sentryException(error, {})
      toast.error('There was an issue loading your reports. Please try again.')
    }
  }

  const fetchAllLocationAssetsById = async (id) => {
    loading.value = true
    try {
      const response = await usePortalAPI(
        `controlpanel/locations/${id}/assets/`,
        {
          method: 'GET',
        },
      )
      if (response) {
        locationAssets.value = response
      }
    } catch (error) {
      throw error
    }
    loading.value = false
  }

  const fetchLocationAssetByIdAndFilterByDate = async (
    id,
    startDate,
    endDate,
    timePeriod
  ) => {
    loading.value = true
    try {
      const response = await usePortalAPI(`dashboard/assets/${id}/data/`, {
        method: 'GET',
        query: {
          ...(timePeriod && { time_period: timePeriod }),
          ...(startDate && { start_timestamp: startDate }),
          ...(endDate && { end_timestamp: endDate }),
        },
      })
      if (response) {
        selectedAssets.value = [response, ...selectedAssets.value]
      }
    } catch (error) {
      throw error
    }
    loading.value = false
  }

  const updateLocationAssetByIdAndFilterByDate = async (
    id,
    startDate,
    endDate,
    timePeriod
  ) => {
    loading.value = true
    try {
      const response = await usePortalAPI(`dashboard/assets/${id}/data/`, {
        method: 'GET',
        query: {
          ...(timePeriod && { time_period: timePeriod }),
          ...(startDate && { start_timestamp: startDate }),
          ...(endDate && { end_timestamp: endDate }),
        },
      })
      if (response) {
        selectedAssets.value = selectedAssets.value.map((asset) =>
          asset.id === id ? response : asset,
        )
      }
    } catch (error) {
      throw error
    }
    loading.value = false
  }

  const removeSelectedLocationAssetFromList = (id) => {
    selectedAssets.value = selectedAssets.value
      .filter((asset) => asset.id !== id)
      .map((assets) => assets)
  }

  const resetActiveProject = () => {
    activeProject.value = null
  }

  const setActiveLocationById = async (id) => {
    const locationExists =
      locations.value.find((location) => location.id === parseInt(id)) || null
    if (locationExists === null) {
      throw new Error()
    }
    activeLocation.value = locationExists
  }

  const resetActiveLocation = () => {
    activeLocation.value = null
  }

  const resetLocationAssets = () => {
    locationAssets.value = null
  }

  const resetSelectedLocationAssets = () => {
    selectedAssets.value = []
  }

  return {
    fetchProjectById,
    fetchAllProjects,
    fetchProjectUsersById,
    fetchProjectReportsById,
    fetchAllLocationAssetsById,
    fetchLocationAssetByIdAndFilterByDate,
    updateLocationAssetByIdAndFilterByDate,
    removeSelectedLocationAssetFromList,
    resetActiveProject,
    setActiveLocationById,
    resetActiveLocation,
    resetLocationAssets,
    resetSelectedLocationAssets,
    projects,
    projectUsers,
    loading,
    activeProject,
    locations,
    hasRAG,
    activeLocation,
    reports,
    locationAssets,
    filteredAssets: selectedAssets,
  }
})
