<template>
  <div
    id="outer-structure"
    class="h-screen"
    :class="[menuOpen ? 'w-64' : 'w-24', 'z-10']"
  >
    <div
      v-if="loading"
      id="skeleton"
      class="h-full bg-white border-r flex flex-col justify-between items-center p-5 z-10"
    >
      <div class="flex flex-col items-center w-full space-y-6 min-h-[120px]">
        <div
          class="min-w-[64px] min-h-[64px] max-w-[64px] max-h-[64px] bg-background rounded-full"
        ></div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="bg-gradient-to-b from-[#3990FF] to-[#0047FF] flex flex-col h-full justify-between items-center p-5 relative"
    >
      <!-- toggle sidebar -->
      <div
        class="transition-all duration-500 ease-in-out hover:scale-[1.1] absolute top-1/4 -right-[15px] w-10 h-10 shadow-xl rounded-full bg-white border-3 hover:bg-primary-container cursor-pointer"
        @click="menuOpen = !menuOpen"
      >
        <fa-icon
          icon="chevron-right"
          :class="[
            menuOpen ? 'rotate-[-180deg]' : 'hover:text-atmo-primary60c',
            'transition duration-300 ease-in-out text-atmo-primary60 h-10 w-10 scale-[0.6]',
          ]"
        />
      </div>
      <div
        class="flex flex-col items-center justify-between w-full space-y-6 h-full"
      >
        <ul class="flex flex-col items-first w-full space-y-10">
          <router-link :to="{ name: 'loading' }">
            <div class="flex flex-col items-center w-full">
              <img
                src="@/assets/icons/atmo-core-logo-unfilled.png"
                alt="atmo-logo"
                :class="[
                  menuOpen
                    ? 'min-w-[64px] min-h-[64px] max-w-[64px] max-h-[64px]'
                    : 'w-[40px] h-[40px]',
                  'scale-1 cursor-pointer transition duration-300 ease-in-out',
                ]"
                data-tip="hello"
              />
            </div>
          </router-link>
          <li v-if="user.is_superuser">
            <SidebarNavItem
              v-for="(routeConfig, index) in adminRoutes"
              :key="index"
              :route-config="routeConfig"
              :extended="menuOpen"
            />
          </li>
        </ul>
        <br />
        <div class="flex flex-col items-center w-full space-y-6 min-h-[120px]">
          <div class="min-w-16 flex-none">
            <CompanyIcon v-if="activeProject" :project="activeProject" />
            <div
              v-else-if="route.name === 'projectPage'"
              id="skeleton-loading"
              class="w-16 h-16 bg-background rounded-lg animate-pulse"
            ></div>
          </div>
          <div class="h-8 w-full flex justify-center">
            <p v-if="activeLocation !== null" class="text-white text-center">
              {{ activeLocation.name }}
            </p>
          </div>
        </div>
        <div
          v-if="route.matched.some((r) => r.path.includes('/project'))"
          id="menu-section"
          class="h-96 w-full flex justify-start"
        >
          <ul
            v-if="!route.matched.some((r) => r.path.includes('/location'))"
            class="flex flex-col items-first w-full space-y-10 flex-1"
          >
            <SidebarNavItem
              v-for="(routeConfig, index) in projectRoutesForNavigation"
              :key="index"
              :route-config="routeConfig"
              :extended="menuOpen"
            />
          </ul>
          <ul
            v-if="route.matched.some((r) => r.path.includes('/location'))"
            class="flex flex-col items-first w-full space-y-10"
          >
            <SidebarNavItem
              v-if="user.is_project_user || user.is_superuser"
              icon="chevron-left"
              label="Back to Locations"
              :extended="menuOpen"
              @click="
                router.push({
                  name: 'projectPage',
                  params: { projectId: route.params.projectId },
                })
              "
            />
            <SidebarNavItem
              v-for="(routeConfig, index) in locationRoutesForNavigation"
              :key="index"
              :route-config="routeConfig"
              :extended="menuOpen"
            />
          </ul>
        </div>

        <ul class="flex flex-col items-first w-full space-y-10">
          <ProfileDisplay
            :user="user"
            :extended="menuOpen"
            @click="openProfileImageModal"
          />
          <li class="">
            <div
              :class="[
                menuOpen ? 'px-3' : 'justify-center',
                'text-white h-[42px] hover:scale-[1.03] space-x-4 flex items-center py-3 overflow-hidden rounded-xl hover:bg-primary-container hover:text-atmo-primary60 hover:shadow-sm transition-all duration-300 ease-in-out cursor-pointer',
              ]"
              @click="authStore.$logout"
            >
              <fa-icon icon="right-from-bracket" class="text-[20px]" />
              <p :class="{ hidden: !menuOpen }">Logout</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/store/Auth'
import { useProjectStore } from '@/store/Project.js'
import { storeToRefs } from 'pinia'
import SidebarNavItem from '@/components/navigation/SidebarNavItem.vue'
import ProfileDisplay from '@/components/navigation/ProfileDisplay.vue'
import ProfileDialog from '@/components/dialogs/ProfileDialog.vue'
import { useModalStore } from '@/store/Modal'
import CompanyIcon from '@/components/CompanyIcon.vue'
import { useSettingsStore } from '@/store/Settings'

const authStore = useAuthStore()
const { user, loading } = storeToRefs(authStore)

const projectStore = useProjectStore()
const { activeProject, activeLocation } = storeToRefs(projectStore)

const settingsStore = useSettingsStore()
const { menuOpen } = storeToRefs(settingsStore)

const modalStore = useModalStore()
const openProfileImageModal = () => {
  modalStore.openModal({
    component: ProfileDialog,
    props: {
      onUpdate: updateUserProfileImage,
      profileImage: user.value.profileImage,
      errorMessage: updateUserProfileImageErrorMessage.value,
    },
  })
}

const updateUserProfileImageErrorMessage = ref(null)
const updateUserProfileImage = async (file) => {
  const fd = new FormData()
  const profileData = {
    image: file,
  }
  Object.entries(profileData).forEach(([key, value]) => {
    fd.append(key, value)
  })
  try {
    await authStore.updateUserProfileImage(fd)
    modalStore.closeModal()
  } catch (error) {
    updateUserProfileImageErrorMessage.value = error.response
  }
}

const router = useRouter()
const route = useRoute()

/**
 * Recursively create a flatmap of all the menu items from the routes stored in the router index file.
 * @param groups 
 */
const recursivelyGenerateMenu = (groups) => (groups || []).flatMap(({children, ...o}) => [o, ...recursivelyGenerateMenu(children)])
const menuItems = recursivelyGenerateMenu(router.options.routes)

const adminRoutes = menuItems.filter((r) => r.meta?.admin)
const projectRoutesForNavigation = menuItems.filter((item) => item.meta?.projectMenu)
const locationRoutesForNavigation = menuItems.filter((item) => item.meta?.locationMenu)

</script>
