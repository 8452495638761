export const defaultDateSortOptions = [
    {   
        id: 1,
        display: 'Last 24 hours',
        short: '24h',
        value: '24h',
        time: 'hour'
    },
    {
        id: 2,
        display: 'Last 7 days',
        short: '7d',
        value: '7d',
        time: 'day'
    },
    {
        id: 3,
        display: 'Last 14 days',
        short: '14d',
        value: '14d',
        time: 'day'
    },
    {
        id: 4,
        display: 'Last 30 days',
        short: '30d',
        value: '30d',
        time: 'day'
    },
    {
        id: 5,
        display: 'Absolute date',
        short: 'AD',
        dates: null,
        time: 'day'
    }
  ]