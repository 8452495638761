import Base64 from 'crypto-js/enc-base64'
import SHA256 from 'crypto-js/sha256'

/**
 * @param {string} str
 * @return {string} base64 encoded string
 * */
export const base64URLEncode = (str) => {
  return str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

/**
 * @param {string} buffer
 * @return {string} base64 encoded sha256
 * */
export const sha256 = (buffer) => {
  return Base64.stringify(SHA256(buffer))
}

/**
 * @param {Date} timestampUnix
 * @returns {string} Date
 */
export const humanReadableUnixDate = (timestampUnix) => {
  const date = new Date(timestampUnix * 1000)
  return date.toLocaleTimeString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })
}


export const formatDateForDropdownDisplay = (date) => {
  if (date && date.length <= 1) return
  const startDate = date[0].toLocaleTimeString('default', {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })
  const endDate = date[1].toLocaleTimeString('default', {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })

  return `${startDate} - ${endDate}`
}