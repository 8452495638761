import { defineStore } from 'pinia'
import { ref } from 'vue'
import { usePortalAPI } from '@/composables/usePortalAPI'
import { sentryException } from '@/plugins/sentry.js'
import { toast } from 'vue-sonner'
import { defaultDateSortOptions } from '@/components/actions/CoreDateDropdown/defaultOptions'

export const useMetricStore = defineStore('metricStore', () => {
    const kpis = ref([])
    const activeKpi = ref()
    const metric = ref({})
    /**
     * Using a global store ref to control the CoreDateDropdown.
     * 
     * Allows for seamless switching between KPI and Metric pages
     * whilst retaining the same date range.
     */
    const selectedDateAction = ref(defaultDateSortOptions[1])

    const fetchKpisForLocationById = async (id, startDate, endDate, timePeriod) => {
        try {
            const response = await usePortalAPI(`kpis/location/${id}/`, {
              method: 'GET',
              query: {
                ...(timePeriod && { time_period: timePeriod }),
                ...(startDate && { start_timestamp: startDate }),
                ...(endDate && { end_timestamp: endDate }),
              }
            })
            if (response) {
              kpis.value = response
            }
          } catch (error) {
            sentryException(error, {})
            toast.error('There was an issue loading this locations KPIs. Please try again.')
          }
    }

    const fetchKpiById = async (id, startDate, endDate, timePeriod) => {
      try {
          const response = await usePortalAPI(`kpis/kpi/${id}/`, {
            method: 'GET',
            query: {
              ...(timePeriod && { time_period: timePeriod }),
              ...(startDate && { start_timestamp: startDate }),
              ...(endDate && { end_timestamp: endDate }),
            }
          })
          if (response) {
            activeKpi.value = response
          }
        } catch (error) {
          sentryException(error, {})
          toast.error('There was an issue loading this locations KPIs. Please try again.')
        }
  }

    const fetchMetricsForKpiById = async (id, startDate, endDate, timePeriod) => {
      try {
          const response = await usePortalAPI(`metrics/kpi/${id}/`, {
            method: 'GET',
            query: {
              ...(timePeriod && { time_period: timePeriod }),
              ...(startDate && { start_timestamp: startDate }),
              ...(endDate && { end_timestamp: endDate }),
            }
          })
          if (response) {
            metric.value = response
          }
        } catch (error) {
          metric.value = null
          if (error.response.status === 404) {
            return
          }
          sentryException(error, {})
          toast.error('There was an issue loading metrics for this Kpi. Please try again.')
        }
  }

    return {
        activeKpi,
        kpis,
        metric,
        selectedDateAction,
        fetchKpiById,
        fetchKpisForLocationById,
        fetchMetricsForKpiById,
    }
})

